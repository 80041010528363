import { requestAutocomplete } from '@/api/autocomplete'

import { WINDOW_LOCATION, VUE_ROUTER } from '../constants/direct_method'
import { STOCK, TAG, NEWS } from '../constants/autocomplete_category'

export default {
  props: {
    defaultPlaceholderText: {
      type: String,
      default: '輸入台／美股代號，查看公司價值',
    },
    directStockMethod: {
      type: String,
      default: WINDOW_LOCATION,
      validator(value) {
        return [WINDOW_LOCATION, VUE_ROUTER].indexOf(value) !== -1
      },
    },
  },

  data() {
    return {
      inputKeyword: '',
      autocompleteResult: {},
      isInputOnFocus: false,
      isAutocompleteLoading: false,
      isRequestAutocompleteError: false,
      currentSelectedItem: null,
      abortController: null,
    }
  },

  computed: {
    isCurrentPathInAnalysisPage() {
      const pathnameArray = window.location.pathname.split('/')
      return pathnameArray[1] === 'analysis'
    },
    placeHolderText() {
      return this.isInputOnFocus ? '' : this.defaultPlaceholderText
    },
    stockList() {
      const stockList = this.autocompleteResult.stocks || []
      const sortedStockList = stockList.sort((stockA, stockB) => {
        // 根據正規表達式 match 的數量做評分排序
        const regex = new RegExp(`\\b(${this.inputKeyword})(\\s)?`, 'gi')
        const stockAScore = [...stockA.displayContent.matchAll(regex)][0]?.filter(Boolean)?.length || 0 // eslint-disable-line max-len
        const stockBScore = [...stockB.displayContent.matchAll(regex)][0]?.filter(Boolean)?.length || 0 // eslint-disable-line max-len

        return stockBScore - stockAScore
      })

      return this.markSearchResultHighlight(sortedStockList)
        .map((item, index) => ({
          ...item,
          id: `${STOCK}-${index}`,
          category: STOCK,
        }))
    },
    tagList() {
      const list = this.autocompleteResult.tags || []
      return this.markSearchResultHighlight(list)
        .map((item, index) => ({
          ...item,
          id: `${TAG}-${index}`,
          category: TAG,
        }))
    },
    newsList() {
      const list = this.autocompleteResult.news || []
      const result = this.markSearchResultHighlight(list)
        .slice(0, 3)
        .map((item, index) => ({
          ...item,
          id: `${NEWS}-${index}`,
          category: NEWS,
        }))

      if (list.length > 3) {
        result.push({
          displayContent: '搜尋結果頁',
          url: `/news/search/${this.inputKeyword}`,
          label: '',
          id: `${NEWS}-search-page`,
          category: NEWS,
        })
      }

      return result
    },
  },

  methods: {
    pressKeyUpHandler(event) {
      // 避免中文輸入法選字時觸發目前事件行為
      if (event.isComposing) {
        return
      }
      this.$refs.AutocompleteList?.selectPrev()
    },
    pressKeyDownHandler(event) {
      // 避免中文輸入法選字時觸發目前事件行為
      if (event.isComposing) {
        return
      }
      this.$refs.AutocompleteList?.selectNext()
    },
    pressKeyEnterHandler(event) {
      // 避免中文輸入法選字時觸發目前事件行為
      if (event.isComposing) {
        return
      }
      this.autocompleteDirectHandler()
    },
    onFocusHandler() {
      this.isInputOnFocus = true
      this.inputKeyword = ''

      this.autocompleteResult = {}

      document.body.addEventListener('click', this.clickOutSideEventListener)
    },
    clickOutSideEventListener() {
      if (!this.$el.contains(document.activeElement)) {
        this.autocompleteResult = {}
        document.body.removeEventListener('click', this.clickOutSideEventListener, false)
      }
    },
    requestAutocomplete(term) {
      if (this.abortController !== null) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()
      this.isAutocompleteLoading = true
      this.isRequestAutocompleteError = false
      requestAutocomplete({
        term,
        signal: this.abortController.signal,
      })
        .then(({ data }) => {
          this.isAutocompleteLoading = false
          this.autocompleteResult = data.data
        })
        .catch((error) => {
          if (error.name === 'CanceledError') {
            return
          }
          this.isAutocompleteLoading = false
          this.isRequestAutocompleteError = true
        })
    },

    directToAnalysis({ ticker }) {
      const isNotSignedInAnalysisPageCondition = this.isCurrentPathInAnalysisPage
        && this.directStockMethod === WINDOW_LOCATION

      if (isNotSignedInAnalysisPageCondition) {
        this.directNotSignedInAnalysisStockToStock({ ticker })
        return
      }

      if (this.directStockMethod === WINDOW_LOCATION) {
        window.location.assign(`/analysis/${ticker}`)
        return
      }

      if (this.$route.params.ticker === ticker) {
        return
      }

      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params, ticker },
      })
    },
    directNotSignedInAnalysisStockToStock({ ticker }) {
      const indicator = window.location.pathname.split('/')[3]

      if (indicator === undefined) {
        window.location.assign(`/analysis/${ticker}`)
        return
      }

      window.location.assign(`/analysis/${ticker}/${indicator}`)
    },

    markSearchResultHighlight(list) {
      return list.map((item) => {
        const escapedKeyword = this.inputKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        const regex = new RegExp(`(${escapedKeyword})`, 'ig')
        const highlightContent = item.displayContent.replace(regex, '<mark class="highlight">$1</mark>')
        return {
          ...item,
          highlightContent,
        }
      })
    },

    updateCurrentSelectedItem(selectedItem) {
      this.currentSelectedItem = selectedItem
    },
  },
}
